<template>
    <a-card :bordered="false" class="header-solid h-full" :bodyStyle="{padding: 0,}">
        <template #title>
			<a-row type="flex" align="middle">
				<a-col :span="24" :md="12">
					<h5 class="font-semibold m-0">{{category.name}}</h5>
				</a-col>
				<a-col :span="24" :md="12" style="display: flex; align-items: center; justify-content: flex-end">
				</a-col>
			</a-row>
		</template>
        <a-checkbox-group @change="productSelected" style="display:inline">
        <a-table :columns="productcolumns" :data-source="productdata" :pagination="true">
            <template slot="selectorColumn" slot-scope="row">
				<a-checkbox :value="row" style="display:none"></a-checkbox>
			</template>
            <template slot="actionCol" slot-scope="row">
                <a-button shape="circle" icon="delete" @click="removefromcategory(row)"></a-button>
			</template>
        </a-table>
        </a-checkbox-group>
        <a-button class="fab" shape="circle" @click="addProduct">
            <font-awesome-icon icon="plus"/>
        </a-button>
    </a-card>
</template>
<script>
import {getitem} from '@/api/category';
import {removeproductfromcategory} from '@/api/category';

export default({
    data() {
        return {
            productcolumns: [
                {
                    title:"", 
                    dataIndex:'productKey',
                    scopedSlots: { customRender: 'selectorColumn' },
                    width: 50,
                },
                {title:"Name", dataIndex:'name'},
                {title:"Unique Id", dataIndex:'uniqueid'},
                {
                    title:"", 
                    dataIndex:'productKey',
                    key:'b',
                    scopedSlots: { customRender: 'actionCol' },
                    width: 50,
                }
            ],
            productdata:[],
            selectedProducts:[],
            category: {}
        }
    },
    methods:{
        productSelected(selectedValues){
           this.selectedProducts=selectedValues;
        },
        addProduct(){
            this.$router.push({path:'/productsselector',query:{categoryid: this.categoryid}})
        },
        removefromcategory(key){
            removeproductfromcategory({categoryid:this.categoryid, productkey: key}).then(data=>{
                for (var i=0; i < this.productdata.length; i++)
						if (this.productdata[i].productKey==key)
							this.productdata.splice(i,1);
            });
        }
    },
    mounted(){
        getitem(this.categoryid).then(data=>{
            this.productdata = data.products;
            this.category = data;
        })
    },
    props: {
        categoryid: String
    }
}
)
</script>